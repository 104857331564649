import ReportTable from '@/components/Report/ReportTable.vue';
<template>
  <div class="rank">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />

      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top?handLists.top.indexOf('add') != -1:false"
      >
        新增</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="标题">
          <a-input v-model="addFromData.title" />
        </a-form-item>
        <a-form-item label="内容">
          <a-textarea
            v-model="addFromData.content"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
        <a-form-item label="点击量">
          <a-input-number
            :min="0"
            v-model="addFromData.hits"
            style="width:195px"
          />
        </a-form-item>
        <a-form-item label="发布时间">
          <a-date-picker
            show-time
            v-model="addFromData.publish_time"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :locale="locale"
          >
          </a-date-picker>
        </a-form-item>
        <a-form-item label="是否发布">
          <a-radio-group v-model="addFromData.is_publish">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table
      bordered
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <div slot="content" slot-scope="text" style="width:100%;height:90px">
        <vue-scroll :ops="ops" style="width:100%;height:100%">
          <div v-html="text"></div>
        </vue-scroll>
      </div>

      <span slot="hits" slot-scope="text, record">
        <a-input-number
          v-model="record.hits"
          :min="0"
          @focus="saveOldNum(text)"
          @blur="hitsChangeVal(record)"
          v-if="handLists.right?handLists.right.indexOf('setNumber') != -1:false"
        />
        <span v-else>
          {{ record.hits }}
        </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <span
          v-if="
            record.publish_time == 0 && handLists.right?handLists.right.indexOf('publish') != -1:false
          "
        >
          <FontIcon
            @click.native="publish(record.id)"
            title="发布"
            type="icon-fabu"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="record.publish_time == 0&&handLists.right?handLists.right.indexOf('edit') != -1:false">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
        </span>

        <FontIcon
          title="查看详情"
          type="icon-chakan"
          size="22"
          @click.native="chakan(record)"
          v-else
        ></FontIcon>

          <a-divider type="vertical" />

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right?handLists.right.indexOf('del') != -1:false"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"> </FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  messageIndex,
  messageDataSave,
  messageDataPublish,
  messageDelete,
  messageChangeVal,
} from "@/apis/index";

import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 60,
  },
  {
    title: "通知标题",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "通知类型",
    dataIndex: "content_type",
    scopedSlots: { customRender: "content_type" },
     customRender: (text) => {
      if (text == 0) {
        return '原创'
      } else {
        return "关联数据";
      }
    },
     width: 120,
  },
  {
    title: "点击量",
    dataIndex: "hits",
    scopedSlots: { customRender: "hits" },
    width: 120,
  },
  {
    title: "发布时间",
    dataIndex: "publish_time",
    scopedSlots: { customRender: "publish_time" },
    width: 180,
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "未发布";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      locale,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#66BABC", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      oldNum: "",

      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists = val;
    },
  },

  created() {
    this.getMessageIndex();
  },
  methods: {
    saveOldNum(text) {
      this.oldNum = text;
    },
    hitsChangeVal(e) {
      if (this.oldNum == e.hits) {
        return false;
      } else {
        messageChangeVal({ id: e.id, change_value: e.hits }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
          }
        });
      }
    },
    publish(id) {
      messageDataPublish({ id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("发布成功");
          this.getMessageIndex();
        }
      });
    },
    getMessageIndex() {
      messageIndex(this.listParams).then((res) => {
        console.log(res);
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getMessageIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    showModal() {
      this.$router.push({ path: "/systems/AddEditNotice" });

      // this.modalTitle = "新增通知";
      // (this.addFromData = {
      //   id: "",
      //   title: "",
      //   content: "",
      //   hits: "",
      //   publish_time: "",
      //   is_publish: 1,
      // }),
      //   (this.visible = true);


    },
    handleOk(e) {
      this.confirmLoading = true;
      messageDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getMessageIndex();
          this.visible = false;
          this.$message.success("success");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.$router.push({
        path: "/systems/AddEditNotice",
        query: { id: record.id },
      });
    },
    chakan(record) {
      this.$router.push({
        path: "/systems/AddEditNotice",
        query: { id: record.id, look: 1 },
      });
    },

    // editMenu(record) {

      
    //   this.modalTitle = "修改职称信息";
    //   this.addFromData = {
    //     id: record.id,
    //     title: record.title,
    //     content: record.content,
    //     hits: record.hits,
    //     publish_time:
    //       record.publish_time > 0
    //         ? moment(Number(record.publish_time + "000")).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           )
    //         : "",
    //     is_publish: record.publish_time > 0 ? 1 : 0,
    //   };
    //   this.visible = true;
    // },
    confirm(rec) {
      messageDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getMessageIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getMessageIndex();
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getMessageIndex();
    },
  },
};
</script>
<style lang="less" scoped>
.rank {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  // 滚动条位置
  /deep/.__bar-is-vertical {
    right: -1px !important;
  }
  // 隐藏横向滚动条
  /deep/.__bar-is-horizontal {
    display: none !important;
  }
}
</style>
